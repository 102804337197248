import './bootstrap';
import '../css/app.css';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { createPinia } from 'pinia';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import Pusher from 'pusher-js';
import Vue3Toastify from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChartLine, faGlobe, faListUl, faMobileAlt, faPlayCircle, faSignal, faTv, faUpload } from '@fortawesome/free-solid-svg-icons';

library.add(faUpload, faListUl, faTv, faGlobe, faPlayCircle, faSignal, faMobileAlt, faChartLine);

const pinia = createPinia();

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

// Initialize Pusher (if you want a global instance)
const pusher = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    encrypted: true,
});

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });

        app.config.globalProperties.$pusher = pusher; // Make Pusher globally accessible

        return app
            .component('font-awesome-icon', FontAwesomeIcon)

            .use(plugin)
            .use(ZiggyVue)
            .use(pinia)
            .use(Vue3Toastify, {
                autoClose: 3000,
                position: 'top-right',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            })
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
    onError: (error) => {
        console.error('Inertia Error:', error);

        if (error.response && error.response.status === 401) {
            // Handle unauthorized access error
        } else if (error.response && error.response.status === 404) {
            // Handle not found error
        } else {
            // Handle other errors
        }
    },
});
